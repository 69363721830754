import React, { useEffect, useLayoutEffect } from 'react';
import { App } from './app/views';
import AlertPopup from '~components/alertPopup/AlertPopup';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { routes } from '~core/routes';
import { pathToUrl } from '~utils/routes';
import { ScrollToTop } from '~components/ScrollToTop/ScrollToTop';
import { components } from 'react-select';

export default props => {
  const account = useSelector(state => state.auth.account);
  const blockCountry = useSelector(state => state?.blockCountry);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (blockCountry.isBlock && location.pathname !== routes.blockCountry) {
      history.push(pathToUrl(routes.blockCountry));
    }
  }, [history, blockCountry, location]);

  return (
    <App {...props} key={account?.profile?.id}>
      <AlertPopup />
      <ScrollToTop />
    </App>
  );
};
